import React, { useState } from 'react';
import {useSpring, a} from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PortalsButton = (props) => {
	const [isHover, setHover] = useState(false);

	const portalSpring = useSpring({
		from: {
			transform: 'translate(0px,-90px)',
		},
		to: {
			transform:  isHover ? 'translate(0px,-51px)' : 'translate(0px,-90px)', 
		},
		config: {tension: 700, mass: 4, friction: 50}
	});
	return (
		<a.button
			className={props.className}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			style={props.translateOnHover ? portalSpring : null}>
			<FontAwesomeIcon icon={props.icon}/>			
			<span>{props.text}</span>
		</a.button>
	);
};

export default PortalsButton;