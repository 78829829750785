/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage du footer.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/


import React from 'react';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter, faTelegram, faYoutube, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Grid from './../template/grid';
import SectionTitle from './../sections/section-title';
import Logo from './../../images/logo.svg';
import MailTo from '../mailto/mailTo';

const Footer = () => {
	const links = [
		{
			name: 'twitter',
			url: 'https://twitter.com/_nedao_',
			icon: faTwitter
		},
		{
			name: 'Telegram',
			url: 'https://t.me/ne_dao',
			icon: faTelegram
		},
		{
			name: 'Linkedin',
			url: 'https://www.linkedin.com/company/nedao',
			icon: faLinkedin
		},
		{
			name: 'Email',
			url: '#',
			icon: faEnvelope,
		},
		{
			name: 'Discord',
			url: 'https://discord.gg/rgw7Q6R',
			icon: faDiscord
		},
		{
			name: 'Youtube',
			url: 'https://www.youtube.com/channel/UCU5PH3CUoj4vTGMNm9wz9_w',
			icon: faYoutube
		},

	];

	const adresseA = [
		'info@nedao.ch',
	];

	const adresseB = [
		'c/o Microcity',
		'Pierre-à-Mazel 39',
		'CH-2000 Neuchâtel'
	];

	return (
		<footer>
			<Grid>
				<div className="footer-social-ctnr">
					<SectionTitle title="Restez informé" />
					<div className="footer-social">
						{links.map((link, index) => {
							if(link.name === 'Email'){
								return(
									<MailTo
										subject="Nouvelle demande de contact - www.nedao.ch"
									>
										<FontAwesomeIcon icon={link.icon} />
									</MailTo>
								);
							}
							return (
								<a subject={`${link.subject}`} target="_blank" rel="noreferrer" href={link.url} key={index} className={`footer__social-icon ${link.name}`}> 
									<FontAwesomeIcon icon={link.icon} />
								</a>
							);
						})}
					</div>
				</div>
				<Logo />
				<div className="adresse-a">
					<p>Nedao</p>
					{adresseA.map((add, key) => (
						<MailTo key={key}>
							<p >{add}</p>
						</MailTo>
					))}
				</div>
				<div className="adresse-b">
					{adresseB.map((add, key) => (
						<p key={key}>{add}</p>
					))}
				</div>
				<div className="association-nedao-link">
					<p>Association NEDAO:</p>
					<a href="https://association.nedao.ch">association.nedao.ch</a>
				</div>
			</Grid>
		</footer>
	);
};

export default Footer;