/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage de liens externes.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from 'react';
 import PropTypes from 'prop-types';
 import { Helmet } from 'react-helmet';
 import { useStaticQuery, graphql } from 'gatsby';
 
 const Seo = ({description, lang, meta, title, keywords, social}) => {
	 const { site } = useStaticQuery(
		 graphql`
				query {
				  site {
						siteMetadata {
						 title
						 description
						 url
						 keywords
						}
				  }
				}
		  `
	 );
	
	 const url = site.siteMetadata.url
	 
	 const defaultImage = social.image ? social.image.src : '/rocket.png';
	 const defaultType = social.type ? social.type : 'website';
	 const metaDescription = description || site.siteMetadata.description;
	 const defaultTitle = site.siteMetadata?.title;
	 const metaKeyWords = keywords || site.siteMetadata.keywords;
  
	 return (
		 <Helmet
			 htmlAttributes={{
				 lang,
			 }}
			 title={title}
			 titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			 meta={[
				 {
					 name: 'description',
					 content: metaDescription,
				 },
				 {
					 name: 'keywords',
					 content: metaKeyWords
				 },
			 ].concat(meta)}
		 >
			 
			<meta property="og:title" content={title} />
			<meta property="og:type" content={defaultType} />
			<meta property="og:image" content={url + defaultImage} />
			<meta property="og:url" content={`${url}${social.slug ? social.slug : '/'}`} />
			<meta property="og:description" content={metaDescription} />

		 </Helmet>
	 );
 };
  
 Seo.defaultProps = {
	 lang: 'fr',
	 meta: [],
	 description: '',
	 keywords: '',
 };
  
 Seo.propTypes = {
	 description: PropTypes.string,
	 lang: PropTypes.string,
	 meta: PropTypes.arrayOf(PropTypes.object),
	 title: PropTypes.string.isRequired,
	 keywords: PropTypes.string,
 };
  
 export default Seo;