/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage du ruban d'annonce.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import './ruban.scss';
import {useSpring, a} from 'react-spring';


const Ruban = () => {

	const initRuban = useSpring({
		from: { transform: 'translateY(-50px)' },
		to: { transform: 'translateY(0)' },
		config: { mass: 5, tension: 5000, friction: 150 },
	});

	const text = `ℹ📢 Les deux boutons de gauche vous permettront prochainement d'accéder
	à la plateforme NEDAO. 🚧 Encore en développement actif 🚧, inscrivez-vous à la
	newsletter ou suivez-nous sur les réseaux sociaux (liens en bas de page) pour
	ne rien rater ! `

	return (
		<a.div style={initRuban} className="ruban">
			<p className="inner-ruban">
				{text}
			</p>
		</a.div>
	);
};

export default Ruban;
