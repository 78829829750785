/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Composant gérant le mailTo.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO (info@nedao.ch)
@see {@link https://association.nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';

const MailTo = (props) => {
	let _0xe9ad=['\x68\x72\x65\x66','\x6C\x6F\x63\x61\x74\x69\x6F\x6E','\x6D\x61\x69\x6C\x74\x6F\x3A\x69\x6E\x66\x6F\x40\x6E\x65\x64\x61\x6F\x2E\x63\x68'];
	function emailme(subject){window[_0xe9ad[1]][_0xe9ad[0]]= _0xe9ad[2] + '?subject=' + subject;}

	return (
		<a onClick={() => emailme(props.subject)} className="Email" href="#">
			{props.children}
		</a>
	);
};

export default MailTo;
