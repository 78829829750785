/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage du header.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React, {useState, useEffect} from 'react';
import Nav from './nav';
import Portals from './portals';
import './header.scss';
import './burger-menu.scss';
import Grid from '../template/grid';
import BurgerMenu from './burgerMenu';

const Header = () => {

	const [position, setPosition] = useState({
		lastPosition: 0,
		actualPosition: 0,
		scrollUp: true,
		isVisible: true
	});

	const scrollLimit = 50;

	useEffect(() => {
		const scrollEffect = () => {
			const yPosition = window.pageYOffset;
			if(yPosition > position.lastPosition && yPosition > scrollLimit){
				setPosition({
					lastPosition: yPosition,
					actualPosition: yPosition,
					scrollUp: false,
					isVisible: false
				});
	
			} else if(yPosition <= position.lastPosition) {
				setPosition({
					lastPosition: yPosition,
					actualPosition: yPosition,
					scrollUp: true,
					isVisible: true
				});
			}
		};
		window.addEventListener('scroll', scrollEffect);
		return () => {
			window.removeEventListener('scroll', scrollEffect);
		};
	}, [position]);

	return (
		<header>
			<Grid>
				<Portals className="desktop-portals" translateOnHover={true}/>
			</Grid>
			<Nav isVisible={position.isVisible}/>
			<BurgerMenu isVisible={position.isVisible}/>
		</header>
	);
};

export default Header;