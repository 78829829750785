/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage du header.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import { Link } from 'gatsby';
import Grid from '../template/grid';
import Logo from './../../images/logo.svg';


const Nav = () => {

	return (
		<div className="desktop-nav" 			
			data-sal="fade"
			data-sal-delay="300"
			data-sal-easing="ease">
			<nav>
				<Grid>
					<Link to="/">
						<Logo />
					</Link>
					<Link activeStyle={{ opacity: .5 }} to="/news">Articles</Link>
					<Link activeStyle={{ opacity: .5 }} to="/events">Evénements</Link>
				</Grid>
			</nav>
		</div>

	);
};

export default Nav;