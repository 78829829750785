/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description affichage et animation du Burger menu.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import Logo from './../../images/logo.svg';
import {useSpring, a, useTransition, useChain} from 'react-spring';
import Portals from './portals';
const BurgerMenu = (props) => {

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {		
		isOpen ? document.getElementsByClassName('global-wrapper')[0].style.overflowY = 'hidden' : document.getElementsByClassName('global-wrapper')[0].style.overflowY = 'scroll';
		isOpen ? document.getElementsByClassName('global-wrapper')[0].style.height = '100vh' : document.getElementsByClassName('global-wrapper')[0].style.height = 'max-content';
		isOpen ? document.getElementsByClassName('global-wrapper')[0].style.touchAction = 'none' : document.getElementsByClassName('global-wrapper')[0].style.touchAction = 'auto';

	});

	const navRef = useRef();
	const navAnim = useSpring({
		ref: navRef,
		from: { transform: 'translateY(-100vh)' },
		to: { transform: `translateY( ${isOpen ? '0' : '-100vh'})`},
		config: {mass: 10, tension: 500, friction: 150}
        
	});

	/* ================================================================ */
	/* BUTTON SCLICES SPRING ========================================== */
	/* ================================================================ */
	const topBun = useSpring({
		from: { transform: 'scale(1)', top: '0px', opacity: 1 },	
		to: {
			transform: isOpen ? 'scale(0)' : 'scale(1)',
			top: isOpen ? '7px' : '0px',
			opacity: isOpen ? 0 : 1,
		},
	});
	const salade = useSpring({
		to: { transform: isOpen ? 'rotate(45deg)' : 'rotate(0deg)', top: '4px' },
		from: { transform: 'rotate(0deg)' },
	});
	const steak = useSpring({
		to: { transform: isOpen ? 'rotate(-45deg)' : 'rotate(0deg)', top: '-4px' },
		from: { transform: 'rotate(0deg)' },
	});
	const bottomBun = useSpring({
		from: { transform: 'scale(1)', top: '0px', opacity: 1 },
		to: {
			transform: isOpen ? 'scale(0)' : 'scale(1)',
			top: isOpen ? '-6px' : '0px',
			opacity: isOpen ? 0 : 1,
		},
	});

	const showOrHide = {
		opacity: isOpen ? 1 : props.isVisible ? 1 : 0,
		transition: 'all .5s'
	};

	const items = [
		{
			url: '/events',
			title: 'Evenements'
		},
		{
			url: '/news',
			title: 'Articles'
		},
	];

	const transRef = useRef();
	const transitions = useTransition(isOpen ? items : [] , item => item.title, {
		ref: transRef,
		unique: true,
		trail: 400 / items.length,
		from: { opacity: 0, transform: 'translate3d(0,20px,0)', height: 0 },
		enter: { opacity: 1, transform: 'translate3d(0,-20px,0)', height: 50 },
		leave: { opacity: 0, transform: 'translate3d(0,20px,0)', height: 0 }
	});


	useChain(isOpen ? [navRef, transRef] : [transRef, navRef], [0, isOpen ? .5 : 0.7]);

	return (
		<div className="nav-burger-menu">
			<a.nav style={navAnim}>
				<div className="burger-nav">
					{transitions.map(({ item, key, props}) => {
						return(
							<a.div className="nav-burger-item" key={key} style={{...props}}>
								<Link to={item.url} key={key}
								>{item.title}</Link>
							</a.div>
						);
					})}
					<div className="portals">
						<Portals
							translateOnHover={false}
						/>
					</div>
				</div>
			</a.nav>
			<Link style={showOrHide} to="/" className="logo-nav">
				<Logo />
			</Link>
			<button aria-label={isOpen ? 'Fermer le menu' : 'Ouvrir le menu'} className="nav-burger-button" onClick={() => setIsOpen(!isOpen)} style={showOrHide}>
				<a.span 
					className="burger-slice"
					aria-hidden="true" 
					style={topBun}>
				</a.span>
				<a.span
					className="burger-slice"
					aria-hidden="true"
					style={salade}
					top={'4px'}
				></a.span>
				<a.span
					className="burger-slice"
					aria-hidden="true"
					style={steak}
					top={'-4px'}
				></a.span>
				<a.span					
					className="burger-slice"
					aria-hidden="true"
					style={bottomBun}>
				</a.span>
			</button>
		</div>

	);
};

export default BurgerMenu;